import axios from 'axios'

const Api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
})
Api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
    }
    return Promise.reject(error)
  },
)
export default Api
